import {createSettingsParams} from '@wix/tpa-settings'

import {
  mobileLayoutSettingsParams,
  MobileLayoutSettingsParams,
} from '../../../components-shared/Settings/MobileLayout/settingsParams'

export type MobileSettingsParams = MobileLayoutSettingsParams

export const mobileSettingsParams = createSettingsParams<MobileSettingsParams>({
  ...mobileLayoutSettingsParams,
})
