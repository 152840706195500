import React from 'react'

export function createStorageProvider<T extends object>(defaultValues: T) {
  const StorageContext = React.createContext<T>(defaultValues)

  const StorageProvider: React.FC<T> = (props) => {
    return <StorageContext.Provider value={props}>{props.children}</StorageContext.Provider>
  }

  const withStorageProvider = (ProvidedComponent) => {
    return (props: T) => {
      return (
        <StorageProvider {...props}>
          <ProvidedComponent />
        </StorageProvider>
      )
    }
  }

  const useStorage = (): T => {
    return React.useContext(StorageContext)
  }

  return {
    withStorageProvider,
    useStorage,
  }
}
