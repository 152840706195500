import React from 'react'
import {TPAComponentsProvider} from 'wix-ui-tpa/TPAComponentsConfig'
import {useEnvironment} from '@wix/yoshi-flow-editor'

export const RtlProvider: React.FC = ({children}) => {
  const {isRTL} = useEnvironment()

  return (
    <TPAComponentsProvider value={{rtl: isRTL}}>
      <div dir={isRTL ? 'rtl' : undefined}>{children}</div>
    </TPAComponentsProvider>
  )
}
