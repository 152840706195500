import {createStylesParams} from '@wix/tpa-settings'

import {
  mobileDesignStylesParams,
  MobileDesignStylesParams,
} from '../../../components-shared/Settings/MobileDesign/stylesParams'
import {
  mobileLayoutStylesParams,
  MobileLayoutStylesParams,
} from '../../../components-shared/Settings/MobileLayout/stylesParams'

export type MobileStylesParams = MobileDesignStylesParams & MobileLayoutStylesParams

export const mobileStylesParams = createStylesParams<MobileStylesParams>({
  ...mobileDesignStylesParams,
  ...mobileLayoutStylesParams,
})
