import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

import {TextAlignment} from '../../../types/createStylesParams'

export type MobileLayoutSettingsParams = {
  mobileShowTitle: SettingsParamType.Boolean
  mobileShowDescription: SettingsParamType.Boolean
  mobileTextAlignment: SettingsParamType.String
}

export const mobileLayoutSettingsParams = createSettingsParams<MobileLayoutSettingsParams>({
  mobileShowTitle: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  mobileShowDescription: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  mobileTextAlignment: {
    type: SettingsParamType.String,
    getDefaultValue: () => TextAlignment.Center,
  },
})
