import {SettingsParamsType} from '../../types/createStylesParams'
import {
  formSettingsParams,
  FormSettingsParams,
} from '../../components-shared/Settings/FormDesign/settingsParams'

import {displaySettingsParams, DisplaySettingsParams} from './Settings/Tabs/Display/settingsParams'
import {layoutSettingsParams, LayoutSettingsParams} from './Settings/Tabs/Layout/settingsParams'
import {textsSettingsParams, TextsSettingsParams} from './Settings/Tabs/Texts/settingsParams'
import {mobileSettingsParams, MobileSettingsParams} from './Settings.mobile/settingsParams'

export type DesktopSettingsParams = DisplaySettingsParams &
  FormSettingsParams &
  LayoutSettingsParams &
  TextsSettingsParams

type SettingsParams = DesktopSettingsParams & MobileSettingsParams

export const settingsParams: SettingsParamsType<SettingsParams> = {
  ...displaySettingsParams,
  ...formSettingsParams,
  ...layoutSettingsParams,
  ...textsSettingsParams,
  ...mobileSettingsParams,
}
export default settingsParams
