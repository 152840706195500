import {TextFieldTheme} from 'wix-ui-tpa'
import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export type FormSettingsParams = {
  textFieldTheme: SettingsParamType.String
}

export const formSettingsParams = createSettingsParams<FormSettingsParams>({
  textFieldTheme: {
    type: SettingsParamType.String,
    getDefaultValue: () => TextFieldTheme.Box,
  },
})
