import React, {FC} from 'react'
import {useEnvironment, useTranslation, WidgetProps} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa'
import {useSettings, useStyles} from '@wix/tpa-settings/react'

import {withReservationAddOnStorageProvider} from '../storage'
import {allStyleParams as stylesParams} from '../stylesParams'
import {settingsParams} from '../settingsParams'
import {FormLayout, TextAlignment} from '../../../types/createStylesParams'
import {RtlProvider} from '../../../components-shared/RtlProvider'

import {useHooks} from './useHooks'
import {classes, st} from './Widget.st.css'
import {Form} from './components/Form'

const Widget: FC<WidgetProps> = () => {
  const styles = useStyles()
  const settings = useSettings()
  const {shouldShowNoLocations, enabledReservationLocations, isAllLocationsFullyManual} = useHooks()
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()

  const showTitle = settings.get(
    isMobile ? settingsParams.mobileShowTitle : settingsParams.showTitle,
  )
  const showDescription = settings.get(
    isMobile ? settingsParams.mobileShowDescription : settingsParams.showDescription,
  )
  const textAlignment = settings.get(
    isMobile ? settingsParams.mobileTextAlignment : settingsParams.textAlignment,
  )
  const titleText = settings.get(
    isAllLocationsFullyManual ? settingsParams.titleRequestText : settingsParams.titleText,
  )
  const titleTag =
    styles.get(stylesParams.titleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.titleFont).htmlTag

  const descriptionText = settings.get(
    isAllLocationsFullyManual
      ? settingsParams.descriptionRequestsText
      : settingsParams.descriptionText,
  )
  const descriptionTag =
    styles.get(stylesParams.subtitleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.subtitleFont).htmlTag

  const textAlignmentClass = TextAlignmentToClass[textAlignment]

  const formLayout = settings.get(settingsParams.formLayout)

  const formLayoutClass =
    formLayout === FormLayout.Packed || isMobile ? classes.packed : classes.strip

  if (shouldShowNoLocations) {
    return (
      <RtlProvider>
        <div
          className={st(classes.root, {
            mobile: isMobile,
          })}
        >
          <div className={classes.notAcceptingWrapper}>
            <Text className={classes.noAcceptingTitle} tagName="H2">
              {t('uou-reservations.add-on.no-locations-title')}
            </Text>

            <Text className={classes.noAcceptingSubtitle}>
              {t('uou-reservations.add-on.no-locations-subtitle')}
            </Text>
          </div>
        </div>
      </RtlProvider>
    )
  }

  return (
    <RtlProvider>
      <div
        aria-label={t('uou-reservations.add-on.aria-label')}
        className={st(
          classes.root,
          {
            mobile: isMobile,
          },
          formLayoutClass,
        )}
      >
        {showTitle ? (
          <Text tagName={titleTag} className={st(classes.title, textAlignmentClass)}>
            {titleText}
          </Text>
        ) : null}

        {showDescription ? (
          <Text tagName={descriptionTag} className={st(classes.subtitle, textAlignmentClass)}>
            {descriptionText}
          </Text>
        ) : null}

        <Form enabledReservationLocations={enabledReservationLocations} />
      </div>
    </RtlProvider>
  )
}

const TextAlignmentToClass: Record<TextAlignment, string> = {
  [TextAlignment.Left]: classes.textLeft,
  [TextAlignment.Center]: classes.textCenter,
  [TextAlignment.Right]: classes.textRight,
}

export default withReservationAddOnStorageProvider(Widget)
