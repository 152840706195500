import {useEnvironment, useWixSdk} from '@wix/yoshi-flow-editor'
import {useEffect, useMemo, useState} from 'react'
import {useSettings} from '@wix/tpa-settings/react'
import isEqual from 'lodash/isEqual'

import {useReservationAddonStorage} from '../storage'
import {PageType, useBi} from '../../../utils/useBi'
import {
  getEnabledReservationLocations,
  getInitialReservationLocation,
  getIsLocationCompletelyManual,
} from '../../../utils/reservationLocation'
import {settingsParams} from '../settingsParams'
import {FormLayout} from '../../../types/createStylesParams'
import {DisplayConfig} from '../Settings/Tabs/Display/settingsParams'

export const useHooks = () => {
  const {reservationLocations} = useReservationAddonStorage()
  const {isPreview} = useEnvironment()
  const logger = useBi()
  const settings = useSettings()
  useResizeComponent()

  //  settings.get(settingsParams.displayConfig) returns new object on every call
  const displayConfigRaw = settings.get(settingsParams.displayConfig) as DisplayConfig
  const [displayConfig, setDisplayConfig] = useState(displayConfigRaw)

  useEffect(() => {
    setDisplayConfig((prevDisplayConfig) =>
      isEqual(prevDisplayConfig, displayConfigRaw) ? prevDisplayConfig : displayConfigRaw,
    )
  }, [displayConfigRaw])

  const enabledReservationLocations = useMemo(() => {
    let enabledLocations = getEnabledReservationLocations(reservationLocations)

    if (displayConfig.enabledLocations) {
      enabledLocations = enabledLocations.filter(
        (location) => displayConfig.enabledLocations?.includes(location.id!) ?? false,
      )
    }
    if (enabledLocations.length === 0) {
      enabledLocations = [reservationLocations[0]]
    }

    return enabledLocations
  }, [reservationLocations, displayConfig])

  const isAllLocationsFullyManual = useMemo(
    () => enabledReservationLocations.every(getIsLocationCompletelyManual),
    [enabledReservationLocations],
  )

  const selectedReservationLocationId = getInitialReservationLocation(
    enabledReservationLocations,
  )?.id

  const shouldShowNoLocations = !selectedReservationLocationId

  const currentReservationLocation = reservationLocations.find(
    ({id}) => id === selectedReservationLocationId,
  )

  useEffect(() => {
    logger.isLoaded({
      isAddOn: true,
      isMultiLocation: !!reservationLocations.length,
      locationId: currentReservationLocation?.location?.id,
      reservationLocationId: currentReservationLocation?.id,
      isPreview,
      pageType: PageType.widget,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    enabledReservationLocations,
    shouldShowNoLocations,
    isAllLocationsFullyManual,
  }
}

const useResizeComponent = () => {
  const {reservationLocations} = useReservationAddonStorage()
  const {Wix} = useWixSdk()
  const settings = useSettings()
  const {isEditor, dimensions, isMobile} = useEnvironment()
  const formLayout = settings.get(settingsParams.formLayout)
  const enabledReservationLocations = getEnabledReservationLocations(reservationLocations)

  useEffect(() => {
    if (!isEditor || isMobile || !Wix) {
      return
    }

    const minWidth = enabledReservationLocations.length > 1 ? 720 : 600

    if (formLayout === FormLayout.Packed || dimensions.width >= minWidth) {
      return
    }

    Wix.resizeComponent({width: minWidth, height: dimensions.height})
  }, [dimensions])
}
