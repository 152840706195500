import {useBi as useYoshiBi} from '@wix/yoshi-flow-editor'
import {
  tableReservationUouStartOfReservationFunnelReservationPageWidgetISloaded,
  tableReservationUouClickOnReserveNow,
  tableReservationUouClickOnCompleteReservation,
  tableReservationUouStartOfReservationFunnelClickOnFindATable,
} from '@wix/bi-logger-table-reservations-web-uou/v2'
import {
  tableReservationCancelationSucceed,
  tableReservationsClickOnReservationsAttributes,
} from '@wix/bi-logger-table-reservations-web-user/v2'
import {tableReservationCreateReservationSucceed} from '@wix/bi-logger-restaurants/v2'
import {Reservation, Source} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {
  ReservationLocation,
  CustomFieldDefinition,
} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

enum ViewMode {
  preview = 'preview_mode',
  liveSite = 'live_site_mode',
}

export enum PageType {
  page = 'page',
  widget = 'widget',
}

export enum Checkbox {
  check = 'check',
  uncheck = 'uncheck',
}

export enum FieldName {
  location = 'location',
  partySize = 'party_size',
  date = 'date',
  time = 'time',
}

export enum BiReservationType {
  reservation = 'reservation',
  walkIn = 'walk_in',
}

interface CreateReservationParams {
  marketingConsent: boolean
}

interface ReservationCreatedParams {
  isPreview: boolean
  reservation: Reservation
  reservationLocation?: ReservationLocation
  reserveeParams: {
    firstName: string
    lastName?: string
    email?: string
    phone: string
    marketingConsent: boolean
    customFields: {
      [key: string]: string
    }
  }
}

export const useBi = () => {
  const logger = useYoshiBi()

  const isLoaded = ({
    contactId,
    isAddOn,
    isMultiLocation,
    locationId,
    isPreview,
    reservationLocationId,
    pageType,
  }: {
    contactId?: string
    isAddOn: boolean
    isMultiLocation: boolean
    locationId?: string | null
    reservationLocationId?: string | null
    isPreview: boolean
    pageType: PageType
  }) => {
    logger.report(
      tableReservationUouStartOfReservationFunnelReservationPageWidgetISloaded({
        contactId,
        isAddOn,
        isMultiLocation,
        locationGuid: locationId ?? undefined,
        reservationLocationId: reservationLocationId ?? undefined,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
        type: pageType,
      }),
    )
  }

  const clickOnReserveNow = ({
    locationId,
    reservationLocationId,
    isPreview,
    partySize,
    requestedDate,
  }: {
    locationId?: string
    reservationLocationId: string
    isPreview: boolean
    partySize: number
    requestedDate: Date
  }) => {
    logger.report(
      tableReservationUouClickOnReserveNow({
        locationGuid: locationId,
        reservationLocationId,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
        partySize,
        requestedDate: requestedDate.getTime(),
      }),
    )
  }

  const clickOnCompleteReservation = (
    {
      locationId,
      mailCheckbox,
      numInvaldFields,
      numValidFields,
      areaCode,
      isPreview,
      reservationLocationId,
    }: {
      locationId?: string | null
      reservationLocationId?: string | null
      mailCheckbox: boolean
      numInvaldFields: number
      numValidFields: number
      areaCode: string
      isPreview: boolean
    },
    reservation?: Reservation,
  ) => {
    logger.report(
      tableReservationUouClickOnCompleteReservation({
        contactId: reservation?.reservee?.contactId ?? undefined,
        dateTimeValue: reservation?.details?.startDate?.getTime(),
        locationGuid: locationId ?? undefined,
        reservationLocationId: reservationLocationId ?? undefined,
        mailCheckbox: mailCheckbox?.toString() ?? undefined,
        numInvaldFields,
        numValidFields,
        partySize: reservation?.details?.partySize ?? undefined,
        areaCode,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
      }),
    )
  }

  const clickOnFindATable = ({
    datetime,
    isMultiLocation,
    locationId,
    isPreview,
    partySize,
    reservationLocationId,
  }: {
    datetime: Date
    isMultiLocation: boolean
    locationId?: string | null
    reservationLocationId: string
    isPreview: boolean
    partySize: number
  }) => {
    logger.report(
      tableReservationUouStartOfReservationFunnelClickOnFindATable({
        datetime: datetime.getTime(),
        isAddOn: true,
        isMultiLocation,
        locationGuid: locationId ?? undefined,
        reservationLocationId,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
        partySize,
      }),
    )
  }

  const reservationCreated = (params: ReservationCreatedParams) => {
    logger.report(
      tableReservationCreateReservationSucceed({
        saveType: 'create',
        ...getParamsNew(params),
      }),
    )
  }

  const reservationCanceled = (isPreview: boolean, reservation: Reservation) => {
    logger.report(
      tableReservationCancelationSucceed({
        ...getParams(isPreview, reservation),
      }),
    )
  }

  const clickOnReservationsAttributes = ({
    fieldName,
    isAddOn,
    isMultiLocation,
    isPreview,
    locationId,
    reservationLocationId,
  }: {
    fieldName: FieldName
    isAddOn: boolean
    isMultiLocation: boolean
    isPreview: boolean
    locationId?: string | null
    reservationLocationId?: string | null
  }) => {
    logger.report(
      tableReservationsClickOnReservationsAttributes({
        fieldName,
        isAddOn,
        isMultiLocation,
        locationGuid: locationId ?? undefined,
        reservationLocationId: reservationLocationId ?? undefined,
        origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
      }),
    )
  }

  return {
    isLoaded,
    clickOnReserveNow,
    clickOnCompleteReservation,
    clickOnFindATable,
    reservationCreated,
    reservationCanceled,
    clickOnReservationsAttributes,
  }
}

const getParams = (
  isPreview: boolean,
  reservation?: Reservation,
  params?: CreateReservationParams,
) => {
  const duration =
    reservation?.details?.endDate && reservation?.details?.startDate
      ? reservation.details.endDate.getTime() - reservation.details.startDate.getTime()
      : 0

  return {
    creationDate: reservation?.createdDate?.getTime(),
    datetime: reservation?.details?.startDate?.getTime(),
    duration,
    reservationLocationId: reservation?.details?.reservationLocationId ?? undefined,
    partySize: reservation?.details?.partySize ?? undefined,
    reservation__id: reservation?.id ?? undefined,
    reservationType: 'uou',
    mailCheckbox: params?.marketingConsent?.toString() ?? undefined,
    origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
  }
}

const getParamsNew = ({
  isPreview,
  reservation,
  reservationLocation,
  reserveeParams,
}: ReservationCreatedParams) => {
  const createdDate = reservation?.createdDate
  const startDate = reservation?.details?.startDate
  const endDate = reservation?.details?.endDate

  const additionalDetails = reservation?.reservee?.customFields
    ? JSON.stringify(reservation.reservee.customFields)
    : undefined

  const reservationDuration =
    endDate && startDate ? endDate.getTime() - startDate.getTime() : undefined

  const reservationType = reservation?.source
    ? reservation.source === Source.WALK_IN
      ? BiReservationType.walkIn
      : BiReservationType.reservation
    : undefined

  return {
    additionalDetails,
    contactId: reservation?.reservedBy?.contactId ?? undefined,
    creationDate: createdDate?.getTime(),
    mailChecked: reserveeParams?.marketingConsent ?? undefined,
    ...getNumAdditionalDetails(
      reservationLocation?.configuration?.reservationForm?.customFieldDefinitions ?? [],
      reserveeParams.customFields,
    ),
    ...getNumGuestDetail(reserveeParams),
    origin: isPreview ? ViewMode.preview : ViewMode.liveSite,
    partySize: reservation?.details?.partySize ?? undefined,
    reservationId: reservation?.id ?? undefined,
    reservationDuration,
    statusCurrent: reservation.status,
    reservationLocationId: reservation?.details?.reservationLocationId ?? undefined,
    reservationStartDate: reservation?.details?.startDate?.getTime() ?? undefined,
    reservationType,
  }
}

const getNumAdditionalDetails = (
  customFieldDefinitions: CustomFieldDefinition[],
  customFields?: Record<string, string>,
) => {
  let numAdditionalDetailsEmpty: number | undefined
  let numAdditionalDetailsFull: number | undefined
  let numAdditionalDetailsMandatory: number | undefined

  customFieldDefinitions.forEach(({id, required}) => {
    const customFieldValue = id && customFields?.[id] ? customFields[id] : undefined

    if (customFieldValue) {
      numAdditionalDetailsFull = numAdditionalDetailsFull ? numAdditionalDetailsFull + 1 : 1
    } else {
      numAdditionalDetailsEmpty = numAdditionalDetailsEmpty ? numAdditionalDetailsEmpty + 1 : 1
    }

    if (required) {
      numAdditionalDetailsMandatory = numAdditionalDetailsMandatory
        ? numAdditionalDetailsMandatory++
        : 1
    }
  })

  return {
    numAdditionalDetailsEmpty,
    numAdditionalDetailsFull,
    numAdditionalDetailsMandatory,
  }
}

const getNumGuestDetail = (reserveeParams?: ReservationCreatedParams['reserveeParams']) => {
  let numGuestDetailEmpty: number | undefined
  let numGuestDetailFull: number | undefined
  ;[
    reserveeParams?.firstName,
    reserveeParams?.lastName,
    reserveeParams?.phone,
    reserveeParams?.email,
  ].forEach((val) => {
    if (val) {
      numGuestDetailFull = numGuestDetailFull ? numGuestDetailFull + 1 : 1
    } else {
      numGuestDetailEmpty = numGuestDetailEmpty ? numGuestDetailEmpty + 1 : 1
    }
  })

  return {
    numGuestDetailEmpty,
    numGuestDetailFull,
  }
}
