import {createStylesParams, StyleParamType} from '@wix/tpa-settings'

export type MobileTextSizeStylesParams = {
  mobileTitleFontSize: StyleParamType.Number
  mobileSubtitleFontSize: StyleParamType.Number
  mobileBodyTextFontSize: StyleParamType.Number
  mobileFieldInputFontSize: StyleParamType.Number
  mobileFieldLabelFontSize: StyleParamType.Number
  mobileButtonLabelFontSize: StyleParamType.Number
}

export const mobileTextSizeStylesParams = createStylesParams<MobileTextSizeStylesParams>({
  mobileTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 28,
  },
  mobileSubtitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobileBodyTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobileFieldInputFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileFieldLabelFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileButtonLabelFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
})
