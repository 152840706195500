import {StyleParamType, createStylesParams} from '@wix/tpa-settings'

export type MobileLayoutStylesParams = {
  mobileSpaceBetweenTitleAndDescription: StyleParamType.Number
  mobileSpaceBetweenTextAndForm: StyleParamType.Number
  mobileVerticalPadding: StyleParamType.Number
}

export const mobileLayoutStylesParams = createStylesParams<MobileLayoutStylesParams>({
  mobileSpaceBetweenTitleAndDescription: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  mobileSpaceBetweenTextAndForm: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  mobileVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
})
