import {createStylesParams, StyleParamType, wixColorParam, wixFontParam} from '@wix/tpa-settings'

export type GeneralStylesParams = {
  backgroundColor: StyleParamType.Color
  borderColor: StyleParamType.Color
  borderWidth: StyleParamType.Number
  borderRadius: StyleParamType.Number
  titleFont: StyleParamType.Font
  titleColor: StyleParamType.Color
  subtitleFont: StyleParamType.Font
  subtitleColor: StyleParamType.Color
}

export const generalStyleParams = createStylesParams<GeneralStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  borderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {size: 32, htmlTag: 'h2'}),
  },
  titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  subtitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {size: 16, htmlTag: 'p'}),
  },
  subtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
})
