import {useCallback, useEffect, useMemo, useState} from 'react'
import {DropdownOptionProps} from 'wix-ui-tpa'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {zonedTimeToUtc} from '@wix/table-reservations-lib/timezone'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {
  getInitialReservationLocation,
  getReservationLocationById,
  getReservationLocationsOptions,
} from '../../../../../utils/reservationLocation'
import {getPartySizeOptionsForReservationLocation} from '../../../../../utils/partySize'
import {filterDate} from '../../../../../utils/date'
import {getBusinessScheduleFromReservationLocation} from '../../../../../utils/businessSchedule'
import {TimeOption} from '../../../../../utils/timeOptions'
import {useReservationAddonStorage} from '../../../storage'
import {FieldName, useBi} from '../../../../../utils/useBi'
import {useElementWidth} from '../../../../../utils/useElementWidth'
import {useSelectedDateAndTime} from '../../../../../utils/useSelectedDateAndTime'
import {reservationAddOnDataHooks} from '../../constants'

export const useHooks = (enabledReservationLocations: ReservationLocation[]) => {
  const {goToReservationsPage, goToDetailsPage, regionalSettings} = useReservationAddonStorage()

  const {isPreview} = useEnvironment()

  const logger = useBi()

  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  const {width: submitButtonContentWidth, readAndSetWidth: readAndSetSubmitButtonContentWidth} =
    useElementWidth<HTMLButtonElement>(reservationAddOnDataHooks.submitButton())
  const [isLoading, setIsLoading] = useState(false)

  const [selectedReservationLocation, setSelectedReservationLocation] =
    useState<ReservationLocation>()

  useEffect(() => {
    setSelectedReservationLocation(getInitialReservationLocation(enabledReservationLocations))
  }, [enabledReservationLocations])

  const partySizeOptions = useMemo(() => {
    return getPartySizeOptionsForReservationLocation(t, selectedReservationLocation, isMobile)
  }, [selectedReservationLocation, isMobile])

  useEffect(() => {
    const defaultPartySize = partySizeOptions.find(({id}) => Number(id) === 2)
      ? 2
      : Number(partySizeOptions[0].id)

    setSelectedPartySize(defaultPartySize)
  }, [partySizeOptions])

  const [selectedPartySize, setSelectedPartySize] = useState(2)
  const [isManualApproval, setIsManualApproval] = useState(false)

  useEffect(() => {
    const manualApprovalConfig =
      selectedReservationLocation?.configuration?.onlineReservations?.manualApproval

    const shouldBeManualApprove =
      !!manualApprovalConfig?.enabled &&
      selectedPartySize >= manualApprovalConfig.partySizeThreshold!

    setIsManualApproval(shouldBeManualApprove)
  }, [selectedPartySize, selectedReservationLocation])

  const reservationLocationsOptions: DropdownOptionProps[] = getReservationLocationsOptions(
    enabledReservationLocations,
    t,
  )

  const businessSchedule = useMemo(
    () => getBusinessScheduleFromReservationLocation(selectedReservationLocation),
    [selectedReservationLocation],
  )

  const {selectedDate, selectedTime, timeOptions, onSelectedDateChange, onSelectedTimeChange} =
    useSelectedDateAndTime({
      businessSchedule,
      regionalSettings,
      timeZone: selectedReservationLocation?.location?.timeZone,
    })

  const clickOnReservationsAttributesBiParams = useMemo(
    () => ({
      isAddOn: true,
      isMultiLocation: !!enabledReservationLocations.length,
      isPreview,
      locationId: selectedReservationLocation?.location?.id,
      reservationLocationId: selectedReservationLocation?.id,
    }),
    [enabledReservationLocations.length, isPreview, selectedReservationLocation?.id],
  )

  const handleFilterDate = useCallback(
    (date: Date) =>
      filterDate({
        date,
        businessSchedule,
        timeZone: selectedReservationLocation?.location?.timeZone,
        regionalFormat: regionalSettings,
      }),
    [businessSchedule, selectedReservationLocation?.location?.timeZone, regionalSettings],
  )

  const handleLocationChange = (option: DropdownOptionProps) => {
    const newSelectedReservationLocation =
      getReservationLocationById(option.id!, enabledReservationLocations) ??
      enabledReservationLocations[0]

    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      locationId: newSelectedReservationLocation?.location?.id,
      reservationLocationId: newSelectedReservationLocation?.id,
      fieldName: FieldName.location,
    })

    setSelectedReservationLocation(newSelectedReservationLocation)
  }

  const handlePartySizeChange = (option: DropdownOptionProps) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.partySize,
    })
    setSelectedPartySize(Number(option.id))
  }

  const handleDateChange = (date: string | Date) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.date,
    })

    onSelectedDateChange(date as Date)
  }

  const handleTimeChange = (option: TimeOption) => {
    logger.clickOnReservationsAttributes({
      ...clickOnReservationsAttributesBiParams,
      fieldName: FieldName.time,
    })

    onSelectedTimeChange(option)
  }

  const handleFindTableSubmit = async (e) => {
    e.preventDefault()

    readAndSetSubmitButtonContentWidth()

    if (!selectedReservationLocation?.id || !selectedDate || !selectedTime) {
      return null
    }

    logger.clickOnFindATable({
      datetime: selectedDate,
      isMultiLocation: !!reservationLocationsOptions.length,
      locationId: selectedReservationLocation?.location?.id,
      reservationLocationId: selectedReservationLocation?.id,
      isPreview,
      partySize: selectedPartySize,
    })

    setIsLoading(true)

    const params = {
      reservationData: {
        startDate: zonedTimeToUtc(selectedDate, selectedReservationLocation?.location?.timeZone),
        partySize: selectedPartySize,
        reservationLocationId: selectedReservationLocation.id!,
      },
    }
    if (isManualApproval) {
      await goToDetailsPage(params)
    } else {
      await goToReservationsPage(params)
    }
  }

  return {
    t,
    submitButtonContentWidth,
    isMobile,
    regionalSettings,
    reservationLocationsOptions,
    isLoading,
    partySizeOptions,
    timeOptions,
    selectedReservationLocation,
    selectedPartySize,
    selectedDate,
    selectedTime,
    handleLocationChange,
    handlePartySizeChange,
    handleDateChange,
    handleTimeChange,
    handleFindTableSubmit,
    filterDate: handleFilterDate,
  }
}
