import {StyleParamType, wixColorParam, wixFontParam, createStylesParams} from '@wix/tpa-settings'

export type FormBoxStylesParams = {
  formBoxFieldHeight: StyleParamType.Number
  formBoxFillColor: StyleParamType.Color
  formBoxBorderColor: StyleParamType.Color
  formBoxBorderWidth: StyleParamType.Number
  formBoxBorderRadius: StyleParamType.Number
  formBoxSpaceBetweenFields: StyleParamType.Number
  formBoxLabelFont: StyleParamType.Font
  formBoxLabelColor: StyleParamType.Color
  formBoxTextFont: StyleParamType.Font
  formBoxTextColor: StyleParamType.Color
  formBoxSpaceBetweenFieldAndLabel: StyleParamType.Number
}

export const formBoxStylesParams = createStylesParams<FormBoxStylesParams>({
  formBoxFieldHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  formBoxFillColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  formBoxBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  formBoxBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  formBoxBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  formBoxSpaceBetweenFields: {
    type: StyleParamType.Number,
    getDefaultValue: () => 10,
  },
  formBoxLabelFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {size: 14}),
  },
  formBoxLabelColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formBoxTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {size: 16}),
  },
  formBoxTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formBoxSpaceBetweenFieldAndLabel: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
})

export type FormLineStylesParams = {
  formLineBackgroundColor: StyleParamType.Color
  formLineLineColor: StyleParamType.Color
  formLineBorderWidth: StyleParamType.Number
  formLineSpaceBetweenFields: StyleParamType.Number
  formLineLabelFont: StyleParamType.Font
  formLineLabelColor: StyleParamType.Color
  formLineTextFont: StyleParamType.Font
  formLineTextColor: StyleParamType.Color
  formLineSpaceBetweenFieldAndLabel: StyleParamType.Number
}

export const formLineStylesParams = createStylesParams<FormLineStylesParams>({
  formLineBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  formLineLineColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  formLineBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  formLineSpaceBetweenFields: {
    type: StyleParamType.Number,
    getDefaultValue: () => 10,
  },
  formLineLabelFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {size: 14}),
  },
  formLineLabelColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formLineTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {size: 16}),
  },
  formLineTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formLineSpaceBetweenFieldAndLabel: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
})
